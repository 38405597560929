<template>
  <div class="shop-show-preview">

    <!--资源图-->
    <div class="banner" v-if="configData.firstImg">
      <img class="banner-img" :src="configData.firstImg" alt />

      <!--资源信息-->
      <div class="banner-info">
        <img class="logo" src="https://file.aihuamei.cn/base/logo/aihuamei_icon.png" alt="" />
        <div class="banner-info-des">
          <div class="name">画眉名片</div>
          <div class="address">杭州市萧山区民和路479号国泰科技大厦601室</div>
        </div>
      </div>
    </div>

    <!--主题-->
    <div class="visible-overflow" :style="{ 'position': Number(configData.navigationShow) === 1 ? 'sticky' : 'initial' }">
      <div class="tabs-overflow">
        <div class="tabs-content">
          <div
              class="tabs-item"
              v-for="item in configData.shopShowConfig"
              :key="item.contentId"
              :class="{'active-tabs': activeId === item.contentId}"
              :style="activeId === item.contentId ? themeTabsStyle : ''"
              @click="selectedTheme(item)"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>

    <!--系列-->
    <div class="series-overflow" :style="{ 'position': Number(configData.navigationShow) === 1 ? 'sticky' : 'initial' }">
      <div class="series-overflow-hidden">
        <div class="series-content">
          <div class="series-content-tabs">
            <div
                class="tabs-item"
                :class="{'active-series': seriesId === series.contentId}" v-for="(series, seriesIndex) in seriesTabs"
                :style="{'color': seriesId === series.contentId ? '#409EFF' : ''}"
                :key="seriesIndex"
                @click="selectedSeries(series)"
            >
              {{ series.title }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--案例-->
    <div class="case-items" v-for="item in caseList" :key="item.contentId">
      <img class="case-img" v-if="item.coverUrl" :src="item.coverUrl" alt="">
      <GIcon v-else icon="icon-shangchuanwenjian" />
      <div class="case-info">
        <span class="case-name">{{ item.title }}</span>
        <span class="case-des">{{ item.depiction }}</span>
      </div>
    </div>

    <div class="previewImg" v-if="isShowPreview" @click="closePreview">
      <Carousel :autoplay="false" indicator-position="none">
        <CarouselItem v-for="(item, index) in previewImg" :key="index">
          <img :src="item" alt="" @click.stop="closePreview">
        </CarouselItem>
      </Carousel>
    </div>

  </div>
</template>

<script>
  import { Carousel, CarouselItem } from 'element-ui'
  import { activityMedia } from '@/api/businessCard'
  export default {
    name: 'shopShowPreview',
    props: {
      configData: Object
    },
    components: { Carousel, CarouselItem },
    data() {
      return {
        activeId: '',
        seriesId: '',
        seriesTabs: [],
        caseList: [],
        previewImg: [],
        isShowPreview: false
      }
    },
    computed: {
      themeTabsStyle() {
        return {
          '--selected-theme-after': this.configData.navigationBackgroundColor
        }
      },
    },
    watch: {
      'configData': {
        handler: function() {
          this.init()
        },
        deep: true
      }
    },
    methods: {
      init() {
        if (this.configData.shopShowConfig.length) {
          this.selectedTheme(this.configData.shopShowConfig[0])
        } else {
          this.activeId = ''
          this.seriesTabs = []
          this.caseList = []
        }
      },
      // 选择主题
      selectedTheme(item) {
        this.activeId = item.contentId
        this.seriesTabs = item.child
        if (this.seriesTabs && this.seriesTabs.length) {
          this.selectedSeries(this.seriesTabs[0])
        } else {
          this.caseList = []
        }
      },
      // 选择系列
      selectedSeries(item) {
        this.seriesId = item.contentId
        this.caseList = item.child
      },
      // 查看案件
      lockCase(item) {
        activityMedia({ contentId: item.contentId }).then(res => {
          if (!res.data.length) {
            this.$message.warning('暂无内容')
            return
          }
          this.previewImg = res.data.map( v => v.mediaUrl)
          this.isShowPreview = true
        })
      },
      closePreview() {
        this.isShowPreview = false
        this.previewImg = []
      }
    }
  };
</script>

<style lang="scss" scoped>
  --selected-theme-after{
    color: #f00;
  }
.shop-show-preview{
  width: 100%;
  background: #fff;
  height: 100%;
  overflow-y: auto;
  position: relative;
  .banner{
    position: relative;
  }
  .banner-info{
    width: 100%;
    height: vw(80);
    position: absolute;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .8);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 vw(12);
  }
  .logo{
    width: vw(48);
    height: vw(48);
    border-radius: 4px;
    overflow: hidden;
    margin: 0;
  }
  .banner-info-des{
    width: calc(100% - vw(61));
    color: #fff;
    margin-left: vw(12);
    font-size: vw(14);
  }
  .banner-info-des div{
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .address{
    color: #909399;
    font-size: vw(13);
    margin-top: vw(4);
  }
  .banner-img{
    width: 100%;
    height: auto;
  }
  .visible-overflow{
    height: vw(38);
    overflow: hidden;
    background: #fff;
    position: sticky;
    left: 0;
    top: 0;
  }
  .tabs-overflow{
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
  }
  .series-overflow{
    width: 100%;
    position: sticky;
    left: 0;
    top: 25px;
    padding: vw(12);
    overflow: hidden;
    background: #fff;
  }
  .series-overflow-hidden{
    height: 36px;
    border-radius: 4px;
    overflow: hidden;
  }
  .tabs-content{
    width: max-content;
    min-width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-around;
    border-bottom: 1px #f8f8f8 solid;
    font-size: vw(16);
  }
  .series-content{
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    background: $themeBg;
    font-size: vw(14);
    color: $title1;
    border-radius: 4px;
  }
  .series-content-tabs{
    width: max-content;
    min-width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-around;
  }
  .tabs-item{
    cursor: pointer;
    color: #303133;
    font-weight: 500;
    height: vw(40);
    line-height: vw(40);
    margin: 0 vw(16);
    position: relative;
  }
  .active-tabs::after{
    content: '';
    position: absolute;
    left: 0;
    bottom: vw(8);
    width: 100%;
    height: 2px;
    background: var(--selected-theme-after);
  }
  .active-series{
    color: #409EFF;
  }
  .case-items{
    width: 90%;
    margin: 0 auto vw(20);
    font-size: vw(12);
    border-radius: 4px;
    overflow: hidden;
    .case-info{
      width: 100%;
      padding: vw(6);
      color: #fff;
      background: rgba(0, 0, 0, .8);
      span{
        display: block;
      }
    }
    .case-img{
      width: 100%;
      height: vw(140);
      object-fit: cover;
    }
    .case-name{
      font-weight: 600;
      font-size: vw(14);
    }
    .case-des{
      font-size: vw(12);
    }
  }
}
  .previewImg{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    ::v-deep{
      .el-carousel, .el-carousel__container{
        width: 100%;
        height: 100%;
      }
      .el-carousel__item{
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    img{
      width: 375px;
      height: auto;
      object-fit: cover;
    }
  }
</style>
